import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import Storage from "@/misc/Storage";

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,  
});


interface ExtendAxiosHeader extends AxiosRequestHeaders {
    Authorization: string
}

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = Storage.getToken();
  if (!config.noAuthorization) {
    (config.headers as ExtendAxiosHeader).Authorization = `Bearer ${token}`;
  }

  return config;
});
// axios.interceptors.response.use((response) => {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   console.log('a: ', response)
//   return response;
// }, function (error) {
//   console.log('b: ', error)
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   return Promise.reject(error);
// });

export { api };
